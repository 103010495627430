import { graphql } from "gatsby"
import React from "react"

import InitiativeApplication from "../../components/career/initiative-application/initiative-application"
import JobOfferTypes from "../../components/career/job-offer-types/job-offer-types"
import MunzingAsEmployerHeader from "../../components/career/munzing-as-employer-header/munzing-as-employer-header"
import Testimonials from "../../components/career/testimonials/testimonials"
import TextWithImage from "../../components/career/text-with-image/text-with-image"
import WhatAreAdditives from "../../components/career/what-are-additives/what-are-additives"
import PageHeader from "../../components/page-header/page-header"
import RichText from "../../components/rich-text/rich-text"
import SEO from "../../components/seo"
import Tile from "../../components/tile/tile"
import useNav from "../../hooks/useNav"
import { removeLocaleFromUri } from "../../i18n/removeLocaleFromUri"
import { useTranslation } from "../../i18n/translate"
import { dynamicImageWithPublicUrl } from "../../util/dynamic-image"

const MunzingAsEmployerPage = ({ data, location }) => {
  const { t } = useTranslation()

  const recruitingProcessNav = useNav({ uid: "recruiting-process" })
  const rootNav = useNav({
    path: removeLocaleFromUri(location.pathname),
    getRootLevel: true,
  })

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("CAREER-MUNZING_AS_EMPLOYER-META_TITLE")}
        descriptionTranslatedString={t(
          "CAREER-MUNZING_AS_EMPLOYER-META_DESCRIPTION",
        )}
      />
      <PageHeader
        container={"secondary"}
        levelThreeTitle={t("MENU-MUNZING_AS_EMPLOYER_PAGE_TITLE")}
        levelTwoTitle={t("MENU-MUNZING_AS_EMPLOYER_PAGE_TITLE")}
        levelOneTitle={t(rootNav.translationKey)}
      />

      <MunzingAsEmployerHeader
        topInnovator={data.topInnovator}
        topInnovatorThumbnail={data.topInnovatorThumbnail}
      />
      <div className="_fp-global-container-secondary">
        <div className="_fp-grid _fp-grid--gap _fp-grid--margin-top">
          <div className="_fp-col-12 ">
            <TextWithImage
              imagePath={dynamicImageWithPublicUrl(data.whyMunzing)}
              title={t("CAREER-MUNZING_AS_EMPLOYER-WHY_MUNZING-TITLE")}
              copy={t("CAREER-MUNZING_AS_EMPLOYER-WHY_MUNZING-DESCRIPTION")}
            />
          </div>
          <div className="_fp-col-12 ">
            <Tile
              titleTranslatedString={t("GLOBAL-VIRTUAL_3D_TOUR-START_CTA")}
              noTile={true}
              tileSize={"xs"}
            >
              <a
                target={"_blank"}
                style={{ display: "block" }}
                href={`https://my.matterport.com/show/?m=wa3yjDZMY5k`}
              >
                <img
                  style={{
                    width: "100%",
                    display: "block",
                    borderRadius: "10px",
                    aspectRatio: "853/480",
                  }}
                  alt="Virtual 3D-Tour"
                  src={"/images/matterport-tour-thumbnail.jpg"}
                ></img>
              </a>
            </Tile>
          </div>
          <div className="_fp-col-12">
            <JobOfferTypes />
          </div>
          <Testimonials />
          <WhatAreAdditives />
          <div className="_fp-col-12">
            <InitiativeApplication
              textTranslatedString={t(
                "CAREER-MUNZING_AS_EMPLOYER-APPLICATION_PROCESS-DESCRIPTION",
              )}
              titleTranslatedString={t(
                "CAREER-MUNZING_AS_EMPLOYER-APPLICATION_PROCESS-TITLE",
              )}
              linkPath={recruitingProcessNav.url}
              linkTextTranslatedString={t(
                "CAREER-MUNZING_AS_EMPLOYER-APPLICATION_PROCESS-CTA",
              )}
            ></InitiativeApplication>
          </div>
          <div className="_fp-col-12">
            <Tile
              titleTranslatedString={t(
                "CAREER-MUNZING_AS_EMPLOYER-CODE_OF_ETHICS-TITLE",
              )}
            >
              <RichText
                textTranslationKey={
                  "CAREER-MUNZING_AS_EMPLOYER-CODE_OF_ETHICS-DESCRIPTION"
                }
              />
            </Tile>
          </div>
        </div>
      </div>
    </>
  )
}

export default MunzingAsEmployerPage

export const query = graphql`
  {
    whatDoesMunzingDo: strapiDynamicImage(
      uid: { eq: "CAREER-MUNZING_AS_EMPLOYER-WHAT_DOES_MUNZING_DO" }
    ) {
      ...DynamicImageWithPublicUrl
    }
    whyMunzing: strapiDynamicImage(
      uid: { eq: "CAREER-MUNZING_AS_EMPLOYER-WHY_MUNZING" }
    ) {
      ...DynamicImageWithPublicUrl
    }
    topInnovator: strapiDynamicPdfFile(strapiId: { eq: 6 }) {
      ...DynamicPdfFileParts
    }
    topInnovatorThumbnail: strapiDynamicImage(
      uid: { eq: "CAREER-TOP_INNOVATOR_2023" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
